import {object} from 'prop-types';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {Accordion, Layout, SEO} from '../components';
import '../assets/styles/pages/artists.scss';
import SVGLoader from '../components/complex/SVGLoader/SVGLoader';
import Masonry from 'react-responsive-masonry';
// const {fadeDown, scaleUp, scaleDown} = transitions;
// const {cubicOut} = easings;
import {RiArrowDropDownLine} from 'react-icons/ri';
import useCount from '../hooks/useCounts';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import {VscClose} from 'react-icons/vsc';
import useEventListener from '../hooks/useEventListener';
import useWindowSize from '../hooks/useWindowSize';

const Artists = ({pageContext: {artists_data}, ...props}) => {
    const {width} = useWindowSize();

    const artists_meta_data = artists_data?.data;

    const [artists, setArtists] = useState(null);
    const [activeArtist, setActiveArtist] = useState(null);
    const [professions, setProfessions] = useState(null);
    const stackGridRef = useRef(null);
    const infiniteRef = useRef(null);
    const [marginValue, setMarginValue] = useState(null);
    const [filterListVisible, setFilterListVisible] = useState(null);
    const [artistPage, setArtistPage] = useState(2);
    const [loading, setLoading] = useState(false);
    const [loadingArtists, setLoadingArtists] = useState(false);
    const [lastPage, setLastPage] = useState(false);
    const [disableFilter, setDisableFilter] = useState(false);
    const count = useCount();
    const [filter, setFilter] = useState(null);
    const [blockInView, setBlockInView] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [searchNotFound, setSearchNotFound] = useState(false);
    const [searchValue, setSearchValue] = useState(null);
    const dropRef = useRef();

    const getArtists = (profession, page = 1, search) => {
        return fetch(
            `${process.env.API_URL}/api/artists?page=${page}${
                profession ? `&profession=${profession}` : ''
            }${search ? `&search=${search}` : ''}`
        )
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                setLastPage(res.last_page);
                return res;
            });
    };

    function handleLoadMore() {
        setLoading(true);
        if (artistPage >= lastPage + 1) {
            setLoading(false);
            setBlockInView(false);
            return false;
        } else {
            setArtistPage(artistPage + 1);
            getArtists(filter, artistPage, searchValue).then((newPage) => {
                const finalData = newPage?.data ? newPage.data : newPage;
                setLoading(false);
                setArtists([...artists, ...finalData]);
                setBlockInView(false);
            });
        }
    }

    function seeMoreLoad() {
        return false;
    }

    // const infiniteRef = useInfiniteScroll({
    //     loading,
    //     hasNextPage: true,
    //     onLoadMore: () => {
    //         return artistPage - 1 >= 5 ? seeMoreLoad() : handleLoadMore()
    //     }
    // });

    const getProfessions = () => {
        fetch(`${process.env.API_URL}/api/professions`)
            .then((response) => response.json())
            .then((res) => {
                setProfessions(res)
            });
    };

    useEffect(() => {
        artists?.length &&
        setMarginValue((stackGridRef.current.offsetWidth * 0.16) / 3);
    }, [props.size, artists]);

    useEffect(() => {
        getArtists().then((res) => {
            const finalData = res.data ? res.data : res;
            setArtists(finalData);
        });
        getProfessions();
    }, []);

    useEffect(() => {
        window?.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [filter]);

    const gambitGalleryIsInView = (el) => {
        if (el) {
            const scroll = window.scrollY || window.pageYOffset;
            const boundsTop = el.getBoundingClientRect().bottom + scroll;

            const viewport = {top: scroll, bottom: scroll + window.innerHeight};
            const bounds = {top: boundsTop, bottom: boundsTop + el.clientHeight};

            return (
                (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
                (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
            );
        }
    };

    const handleScroll = () => {
        const elem = infiniteRef.current;
        if (elem && gambitGalleryIsInView(elem) && !blockInView) {
            setBlockInView(true);
        }
    };

    useEffect(() => {
        blockInView && (artistPage - 1 >= 5 ? seeMoreLoad() : handleLoadMore());
    }, [blockInView]);

    const filterByProfession = (e, profession) => {
        setDisableFilter(true);
        setLoadingArtists(true);
        setArtists(null);
        setArtistPage(2);
        setBlockInView(false);
        setFilterListVisible(null);

        if (filter !== profession) {
            setFilter(profession);
            getArtists(profession, 1, null)
                .then((res) => {
                    const finalData = res.data ? res.data : res;
                    setArtists(finalData);
                })
                .then(() => {
                    setLoadingArtists(false);
                    setDisableFilter(false);
                    setSearchValue(null);
                    document.getElementById('search').value = '';
                });
        } else {
            setFilter(null);
            getArtists(null, 1, null)
                .then((res) => {
                    const finalData = res.data ? res.data : res;
                    setArtists(finalData);
                })
                .then(() => {
                    setLoadingArtists(false);
                    setDisableFilter(false);
                    setSearchValue(null);
                    document.getElementById('search').value = '';
                });
        }
    };

    const handleShowFilter = (filterName) => {
        filterListVisible === filterName
            ? setFilterListVisible(null)
            : setFilterListVisible(filterName);
    };

    const containsHTML = (str) => /<[a-z][\s\S]*>/i.test(str);

    const containsOnlySpaces = (str) => !str.replace(/\s/g, '').length;

    const handleSearch = (e) => {
        const {value} = e.target;

        if (e.key === 'Enter' || e.keyCode === 13) {
            if (value.length < 3) {
                setErrorMessage('Character count must be longer than 3.');
            } else if (containsHTML(value)) {
                setErrorMessage('Text must be only characters.');
            } else if (containsOnlySpaces(value)) {
                setErrorMessage('Text contains only spaces.');
            } else {
                setFilterListVisible(null);
                setSearchNotFound(false);
                setErrorMessage(null);
                setDisableFilter(true);
                setLoadingArtists(true);
                setArtists(null);
                setArtistPage(2);
                setBlockInView(false);
                setSearchValue(value);

                getArtists(null, 1, value)
                    .then((res) => {
                        const finalData = res.data ? res.data : res;

                        if (finalData.length) {
                            setArtists(finalData);
                        } else {
                            setSearchNotFound(true);
                        }
                    })
                    .then(() => {
                        setLoadingArtists(false);
                        setDisableFilter(false);
                        setFilter(null);
                    });
            }
        }
    };

    const clearSearch = () => {
        setFilterListVisible(null);
        setSearchNotFound(false);
        setErrorMessage(null);
        setDisableFilter(true);
        setLoadingArtists(true);
        setArtists(null);
        setArtistPage(2);
        setBlockInView(false);
        setSearchValue(null);
        document.getElementById('search').value = '';

        getArtists(null)
            .then((res) => {
                const finalData = res.data ? res.data : res;
                setArtists(finalData);
            })
            .then(() => {
                setLoadingArtists(false);
                setDisableFilter(false);
                setFilter(null);
            });
    };

    /*const selectActiveArtist = (event, slug) => {
          const e = artists?.find(item => item.slug === slug)
          setActiveArtist(e);

          const {currentTarget} = event;

          currentTarget.classList.add('activeArtist');
          const el = document.getElementById('initialStateOfArtist');
          let xPosition = (offset(el).left - offset(currentTarget).left);
          let yPosition = (offset(el).top - offset(currentTarget).top);
          currentTarget.style.transform = `translate(${xPosition}px,${yPosition}px)`
          window.history.pushState({}, '', slug);
          setTimeout(() => {
              // currentTarget.style.pointerEvents = 'auto';
              document.getElementById('artistInnerLayout').style.position = 'relative';
              // currentTarget.parentNode.classList.add('stickyDiv');
              // currentTarget.parentNode.style.top = `-${yPosition}px)`;
          }, 1300)
      }

      function offset(el) {
          const rect = el.getBoundingClientRect(),
              scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
              scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
      }

      const instruments = ['Conductor'];*/

    useEventListener('click', (event) => handleMenuOutside(event));

    function handleMenuOutside(e) {
        const {target} = e;
        const {current} = dropRef;

        if (!current?.contains(target)) {
            if (!target.classList.contains('otherFilters')) {
                setFilterListVisible(null);
            }
        }
    }

    const DropDownView = ({p}) => {
        return (
            <span className='filterItemWithDropdown'>
        <div
            className='otherFilters withDropDown'
            onClick={() => handleShowFilter(`showFilter${p.title}`)}
        >
          {p.title}
            <div className='arrowDown'>
            <RiArrowDropDownLine/>
          </div>
        </div>
      </span>
        );
    };


    return (
        <>
            <Layout>
                <SEO
                    title={artists_meta_data?.title || "MusicRoom Projects I Past and Upcoming Projects in the Room"}
                    showDefaultTitle={false}
                    description={artists_meta_data?.description || "MusicRoom managed to engage more than 90 artists"}
                    image={artists_meta_data?.image}
                    keywords={artists_meta_data?.keywords}
                />
                {/* <Helmet title='MusicRoom Artists I Artists in the Room'/> */}

                {/*<div className={`artistInnerLayout ${activeArtist ? 'show' : ''}`} id='artistInnerLayout'>
                <div className='artist__inner'>
                    <div className='artist__header__border'/>

                    <div className='artist__details__parent' id='initialStateOfArtist'>
                        <ArtistCard data={activeArtist ? activeArtist : artists?.[0]}/>
                    </div>

                    <div className='artist projects'>
                        <div className='artist__content'>
                            <div className='artist__content__projects'>
                                <>
                                    <h2 className='content__title'>
                                        Projects with{' '}
                                        <span className='stroked__text'>
                                            {activeArtist ? activeArtist.name : ''} {activeArtist ? activeArtist.surname : ''}
                                        </span>
                                    </h2>
                                    <div className='listing'>
                                        {activeArtist?.projects?.map((concert) => {
                                                return <ProjectCard
                                                    key={`${concert.id}-${concert.slug}`}
                                                    data={concert}
                                                />
                                            }
                                        )}
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>

                    {activeArtist?.related_artist ? (
                        <div
                            className='artist related'
                            id='artist__inner__related'
                        >
                            <div className='artist__content'>
                                <div className='artist__content__related'>
                                    <h2 className='content__title'>ARTISTS IN THE ROOM</h2>

                                    <div className='listing'>
                                        {activeArtist.related_artist.map((relatedArtist, i) => (
                                            <ArtistCard
                                                to={`/artists/${relatedArtist.slug}`}
                                                key={`${relatedArtist.slug}-${i}`}
                                                data={relatedArtist}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>*/}

                <div className='artists'>
                    <div className={`artistsBody ${activeArtist ? 'hideListing' : ''}`}>
                        <div className='artistsBodyHeader'>
                            <div className='artistsBodyHeaderTitle'>
                                <h2>
                                    {count?.artists} artists <br/>
                                    <span className='strokeText'>in the room</span>
                                </h2>
                            </div>
                            <div className='artistsBodyHeaderFilter'>
                                <div className='searchBlock'>
                                    <input onKeyUp={handleSearch} onBlur={() => setErrorMessage(null)} id="search"
                                           type="text" required minLength={1}
                                           spellCheck="false"/>
                                    <label className='searchLabel' htmlFor="search">Search for an Artist</label>
                                    <label className='searchIcon' htmlFor="search"/>
                                    {errorMessage &&
                                    <div className='errorMessage'>
                                        {errorMessage}
                                    </div>
                                    }
                                    {searchValue &&
                                    <div className='clearSearch' onClick={clearSearch}>
                                        <VscClose/>
                                    </div>
                                    }
                                </div>

                                <div className='artistsBodyHeaderFilterItems mobile_d_none'>
                                    {professions?.map((p, i) => (
                                        <Fragment key={i}>
                                        <span
                                            className={`filterItemWithDropdown ${p?.type === 'child' ? 'singleItem' : ''}`}>
                                          <div
                                              className={`otherFilters ${p?.type === 'child' ? '' : 'withDropDown'}`}
                                              onClick={(e) => p?.type === 'child' ? filterByProfession(e, p?.professions_items[0]?.id) : handleShowFilter(`showFilter${p.title}`)}
                                          >
                                              {p?.title}
                                              {p?.type !== 'child' &&
                                              <div className='arrowDown'>
                                                  <RiArrowDropDownLine/>
                                              </div>
                                              }
                                          </div>
                                            {p?.type !== 'child' &&
                                            <div
                                                ref={dropRef}
                                                className={`otherFiltersList ${
                                                    filterListVisible === `showFilter${p?.title}`
                                                        ? 'showList'
                                                        : ''
                                                }`}>
                                                {p?.professions_items?.map((item, i) => (
                                                    <div
                                                        className={`${filter === item.id ? 'activeFilterProfession' : ''} ${disableFilter ? 'disableFilter' : ''}`}
                                                        onClick={(e) => {
                                                            filterByProfession(e, item.id)
                                                        }}
                                                        key={i}
                                                    >
                                                        <span>{item.title}</span>
                                                    </div>
                                                ))}
                                            </div>
                                            }
                                        </span>
                                        </Fragment>
                                    ))}
                                </div>
                                <div className='artistsBodyHeaderFilterItems artist__filter desktop_d_none'>
                                    {professions?.map((p, i) =>
                                        p?.type === 'child'
                                            ?
                                            <span key={i} className='filterItemWithDropdown singleItem'>
                                            <div
                                                className={`otherFilters ${filter === p?.professions_items[0]?.id ? 'activeFilterProfession' : ''}`}
                                                onClick={(e) => filterByProfession(e, p?.professions_items[0]?.id)}>
                                            {p?.title}
                                          </div>
                                        </span>
                                            :
                                            <Accordion key={i} title={<DropDownView p={p}/>}>
                                            <div className='otherFiltersList'>
                                                {p?.professions_items?.map((item, i) => (
                                                    <div
                                                        className={`${filter === item.id ? 'activeFilterProfession' : ''} ${disableFilter ? 'disableFilter' : ''}`}
                                                        onClick={(e) => {
                                                            filterByProfession(e, item.id);
                                                        }}
                                                        key={i}
                                                    >
                                                        <span>{item.title}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </Accordion>
                                    )}
                                </div>
                            </div>
                        </div>
                        {artists?.length ? (
                            <div className='artistsBodyListing' ref={infiniteRef}>
                                {/* <StackGrid */}
                                {/*    columnWidth={width > 768 ? '20.5%' : width > 480 ? '50%' : '100%'} */}
                                {/*    gutterWidth={marginValue} */}
                                {/*    gutterHeight={marginValue} */}
                                {/*    horizontal */}
                                {/* > */}
                                {/*    {data.map((item, i) => */}
                                {/*        <div className='stackGridItem' key={i}> */}
                                {/*            <div className='imgContainer'> */}
                                {/*                <img */}
                                {/*                    src={item.avatar} */}
                                {/*                    alt="asdasda"/> */}
                                {/*            </div> */}
                                {/*            <div className='stackGridItemArtistInfo'> */}
                                {/*                <p className='strokeText stackGridItemArtistInfoName'> */}
                                {/*                    {item.name} <br/> */}
                                {/*                    {item.surname} */}
                                {/*                </p> */}
                                {/*                <p className='stackGridItemArtistInfoProfession'> */}
                                {/*                    {item.profession[0]} */}
                                {/*                </p> */}
                                {/*            </div> */}
                                {/*        </div> */}
                                {/*    )} */}
                                {/* </StackGrid> */}

                                <div className='customStackGrid' ref={stackGridRef}>
                                    {artists ? (
                                        <Masonry
                                            columnsCount={
                                                width > 1024 ? 4 : width >= 768 ? 3 : 2
                                            }
                                            gutter={marginValue + 'px'}
                                        >
                                            {artists.map((item, i) => (
                                                <AniLink className='stackGridItem' key={i} to={`/artists/${item.slug}`}
                                                         fade duration={0.7}>
                                                    {/*<div className='stackGridItem' key={i}*/}
                                                    {/*     onClick={(e) => selectActiveArtist(e, item.slug)}>*/}
                                                    <div className='imgContainer'>
                                                        <img src={item.project_image}
                                                             alt={`${item.name} ${item.surname}`}/>
                                                    </div>
                                                    <div className='stackGridItemArtistInfo'>
                                                        <p className='strokeText stackGridItemArtistInfoName'>
                                                            {item.name} <br/>
                                                            {item.surname}
                                                        </p>
                                                        <p className='stackGridItemArtistInfoProfession'
                                                           style={{opacity: `${!activeArtist ? '1' : '0'}`}}>
                                                            {item?.professions_items?.map((e, i) =>
                                                                item?.professions_items?.length > 1 ? (
                                                                    <span key={i}>{e.title}</span>
                                                                ) : (e.title)
                                                            )}
                                                        </p>
                                                        {activeArtist && instruments && instruments.length && (
                                                            <div className='artist__inner__card__text__instruments'>
                                                                <h3 className={`title ${instruments.includes('conductor') && 'd-none'}`}>INSTRUMENT{instruments.length > 1 && 'S'}</h3>
                                                                <div className='instruments__listing'>
                                                                    {instruments.map((instrument, i) => (
                                                                        <p className='instrument'
                                                                           key={`${instrument}-${i}`}>
                                                                            {instrument}
                                                                        </p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </AniLink>
                                            ))}
                                        </Masonry>
                                    ) : (
                                        <SVGLoader/>
                                    )}
                                    {artistPage - 1 >= 5 && !(artistPage >= lastPage + 1) && !loading &&
                                    <div onClick={handleLoadMore} className='seeMoreButton'>
                                        See More
                                    </div>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    minHeight: '200px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {filter && !loadingArtists
                                    ? (<p>Artists from this category not found!</p>)
                                    : searchNotFound
                                        ? <p>Artist not found!</p>
                                        : <SVGLoader/>
                                }
                            </div>
                        )}

                        {loading && <SVGLoader/>}
                    </div>
                </div>

            </Layout>
        </>
    );
};

Artists.propTypes = {
    pageContext: object.isRequired,
};

export default Artists;
